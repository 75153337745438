import React from "react";

const CharsCounter = ({ minChars, currentCharsLength, translation, idField}) => {
  if (minChars > currentCharsLength) {
    return(
      <span className="ajax-input__addon" id={idField}>
       {translation.join(` ${minChars - currentCharsLength} `)}
      </span>
    )
  } else {
    return <span></span>
  }
};

export default CharsCounter;
