import React from "react";

const ResultElem = (props) => (
  <li
  className={props.selected_option === props.idx ? "has-background-info" : ""}
  onClick={(e) => { e.preventDefault(); props.handle_option_click(props.id, props.title); }}
  >
    <span href="#">
    {props.title}
    </span>
    <span className="has-text-grey-light">
    ({props.cached_path})
    </span>
  </li>
)

export default ResultElem;
