import React from "react";

import ResultElem from "./ResultElem";

const Result = ({ data, selectedOption, handleOptionClick }) => (
  <div id="city-selector-result" className="ajax-input__result">
    <ul>
      {
        data.map((elem, idx) => (
          <ResultElem
            key={elem.id}
            idx={idx}
            selectedOption={selectedOption}
            handleOptionClick={handleOptionClick}
            {...elem}
          />)
        )
      }
    </ul>
  </div>
);

export default Result;
