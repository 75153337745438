import React from "react";

const Form = ({currentValue, handleInput, options}) => (
  <div>
    <div className={`control ${options.loading && "is-loading"}`}>
      <input
        className="input is-rounded"
        type="text"
        placeholder="Введите название фирмы или контактное лицо"
        onChange={(e) => handleInput(e.target.value)}
      />
    </div>
  </div>
)

export default Form;
