import React from "react";
import Form from "./WaybillTracking/Form"
import Waybill from "./WaybillTracking/Waybill"

class WaybillTracking extends React.Component {

  state = {
    dest_region: undefined,
    dest_address: undefined,
    orig_region: undefined,
    orig_address: undefined,
    paid: undefined,
    total_price: undefined,
    quantity: undefined,
    weight: undefined,
    parcels: undefined,
    receiver_title: undefined,
    sender_title: undefined,
    delivery_status: undefined,
    delivery_status_css_class: undefined,
    success_delivery: undefined,
    translations: this.props.translations,
    error: undefined
  }

  setDeliveriesStates(element) {
    if (element.some(this.atLeastHasOneSuccess)) {
      this.setState ({
        delivery_status: this.state.translations.success,
        delivery_status_css_class: "label-success",
        success_delivery: element.find(element => element.status === "success")
      });
    } else if (element.length === 0) {
      this.setState ({
        delivery_status: this.state.translations.inDelivery,
        delivery_status_css_class: "label-info",
        success_delivery: undefined
      });
    } else if (element.every(this.deliveryFailed)) {
      this.setState ({
        delivery_status: this.state.translations.failure,
        delivery_status_css_class: "label-failure",
        success_delivery: undefined
      });
    } else if (element.every(this.deliveryReturned)) {
      this.setState ({
        delivery_status: this.state.translations.returned,
        delivery_status_css_class: "label-failure",
        success_delivery: undefined
      });
    }
  };

  atLeastHasOneSuccess(element) {
    return element.status === "success";
  };

  deliveryFailed(element) {
    return element.status === "failure"
  };

  deliveryReturned(element) {
    return element.status === "return"
  }

  gettingWaybill = async (event) => {
    event.preventDefault();
    const api_path = `http://api.gpserv.work/public/v1/tracking?waybill=`
    const waybill = event.target.elements.waybill.value
    const api_url = await fetch(api_path + `${waybill}`);
    const data = await api_url.json();
    console.log(data)

    if (data.waybill) {
      this.setState ({
        dest_region: data.waybill.dest_region,
        dest_address: data.waybill.dest_address,
        orig_region: data.waybill.orig_region,
        orig_address: data.waybill.orig_address,
        paid: data.waybill.paid,
        total_price: data.waybill.total_price,
        quantity: data.waybill.deliveries.length,
        weight: data.waybill.weight,
        parcels: data.waybill.parcels[0],
        receiver_title: data.waybill.receiver.title,
        sender_title: data.waybill.sender.title,
        error: undefined
      });
      this.setDeliveriesStates(data.waybill.deliveries)
    } else if (data.error) {
      this.setState ({
        dest_region: undefined,
        dest_address: undefined,
        orig_region: undefined,
        orig_address: undefined,
        paid: undefined,
        total_price: undefined,
        quantity: undefined,
        weight: undefined,
        parcels: undefined,
        receiver_title: undefined,
        sender_title: undefined,
        delivery_status: undefined,
        delivery_status_css_class: undefined,
        success_delivery: undefined,
        error: "Waybill not found"
      });
    }
  }

  render() {
    return (
      <div className="waybill-track">
        <Form
          waybillGettingMethod={this.gettingWaybill}
          translations={this.state.translations}
        />
        <Waybill
          dest_region={this.state.dest_region}
          dest_address={this.state.dest_address}
          orig_region={this.state.orig_region}
          orig_address={this.state.orig_address}
          paid={this.state.paid}
          total_price={this.state.total_price}
          quantity={this.state.quantity}
          weight={this.state.weight}
          deliveries={this.state.deliveries}
          parcels={this.state.parcels}
          receiver_title={this.state.receiver_title}
          sender_title={this.state.sender_title}
          delivery_status={this.state.delivery_status}
          delivery_status_css_class={this.state.delivery_status_css_class}
          success_delivery={this.state.success_delivery}
          translations={this.state.translations}
          error={this.state.error}
        />
      </div>
    );
  }
}

export default WaybillTracking;
