import React from "react";

const ResultElem = ({ id, title, cached_path, selectedOption, idx, handleOptionClick }) => (
  <li
    className={selectedOption === idx ? "has-background-info" : ""}
    onClick={(e) => { e.preventDefault(); handleOptionClick(id, title); }}
  >
    <span href="#">
      {title}
    </span>
    <span className="has-text-grey-light">
      ({cached_path})
    </span>
  </li>
);

export default ResultElem;
