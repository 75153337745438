import React from "react";

const Form = props => (
  <form className="waybill-form" onSubmit={props.waybillGettingMethod}>
    <div className="field has-addons">
      <div className="control">
        <input className="input" type="text" name="waybill" placeholder="Номер накладной"/>
      </div>
      <div className="control">
        <button className="button is-link">{props.translations.find}</button>
      </div>
    </div>
  </form>
)

export default Form;
