import React from "react";
import { debounce } from "lodash";

import CharsCounter from "./CharsCounter";
import Result from "./Result";

class CitySelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      minChars: 2,
      currentValue: "",
      currentId: undefined,
      data: [],
      isLoading: false,
      selectedOption: 0
    };
    this.handleInput = this.handleInput.bind(this);
    this.handleOptionClick = this.handleOptionClick.bind(this);
    this.fetchData = debounce(this.fetchData, 350);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  handleInput(value) {
    this.setState(prevState => ({
      currentValue: value,
      data: []
    }), () => this.fetchData(value))
  }

  handleOptionClick(id, value) {
    this.setState(prevState => ({
      ...prevState,
      data: [],
      currentValue: value,
      currentId: id
    }))
  }

  handleKeyPress(e) {
    const { selectedOption } = this.state;
    const { data } = this.state;
    const nextIndex = data.length - 1 <= selectedOption ? 0 : selectedOption + 1;
    const prevIndex = selectedOption > 0 ? selectedOption - 1 : data.length - 1;


    if (e.key === "ArrowDown") {
      e.preventDefault();
      this.setState(prevState => ({
        ...prevState,
        selectedOption: nextIndex
      }))
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      this.setState(prevState => ({
        ...prevState,
        selectedOption: prevIndex
      }))
    } else if (e.key === "Enter") {
      e.preventDefault();
      this.setState(prevState => ({
        ...prevState,
        data: [],
        currentValue: prevState.data[selectedOption] ? prevState.data[selectedOption].title : "",
        currentId: prevState.data[selectedOption] ? prevState.data[selectedOption].id : "",
      }))
    }
  }

  fetchData = async (value) => {
    if (value.length >= this.state.minChars ) {
      this.setState ({ isLoading: true });
      const api_url = await fetch(`http://api.gpserv.work/public/v1/regions/origin?title=${value}`);
      const data = await api_url.json();
      console.log(data);
      if (data.regions.length === 1 && data.regions[0].title === value.charAt(0).toUpperCase() + value.slice(1)) {
        this.setState(prevState => ({
          ...prevState,
          isLoading: false,
          currentValue: data.regions[0].title,
          currentId: data.regions[0].id
        }))
      } else {
        this.setState(prevState => ({
          ...prevState,
          data: data.regions,
          isLoading: false,
          selectedOption: 0
        }))
      }
    } else {
      this.setState(prevState => ({
        ...prevState,
        data: [],
        selectedOption: 0
      }))
    }
  }

  render() {
    return (
      <div className={`control ajax-input ${this.state.isLoading && "is-loading"}`}>
        <div className="field has-addons">
          <p className="control">
            <input
              type="hidden"
              value={this.state.currentValue}
              name={`${this.props.name}_name`}
            />
            <input
              type="hidden"
              value={this.state.currentId}
              name={`${this.props.name}_id`}
            />
            <input
              type="text"
              className="input"
              placeholder={this.props.placeholder}
              name={this.props.name}
              value={this.state.currentValue}
              onChange={(e) => this.handleInput(e.target.value)}
              onKeyDown={(e) =>this.handleKeyPress(e)}
            />
            { this.state.currentValue.length >= this.state.minChars && <Result handle_option_click={this.handleOptionClick} data={this.state.data} selected_option={this.state.selectedOption} />}
          </p>
          <p className="control">
            <a className="button is-static">
              <CharsCounter
                min_chars={this.state.minChars}
                current_chars_length={this.state.currentValue.length}
                translations={this.props.translations}
              />
            </a>
          </p>
        </div>
      </div>
    );
  }
}

export default CitySelector;
