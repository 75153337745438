import React from "react";

class CharsCounter extends React.Component {
  render() {
    if (this.props.min_chars > this.props.current_chars_length) {
      return(
        <span className="ajax-input__addon">
          {this.props.translations.inputsleft} {this.props.min_chars - this.props.current_chars_length} {this.props.translations.symbolsleft}
        </span>
      );
    } else {
      return (
        <span></span>
      );
    }
  }
};

export default CharsCounter;
