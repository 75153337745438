import React from "react";
import axios from "axios";
import { debounce } from "lodash";
import humps from "humps";


import Form from "./AddressSearch/Form";
import Result from "./AddressSearch/Result";

class AddressSearch extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      currentValue: null,
      isLoading: false,
      contactType: props.contactType,
      contacts: [],
    };
    this.handleInput = this.handleInput.bind(this);
    this.fetchData = debounce(this.fetchData, 550);
    this.handleOptionClick = this.handleOptionClick.bind(this)
  }

  componentWillMount() {
    axios.get("/cabinet/addresses/search.json", {
      params: {
        "q": {
          "contact_person_or_organization_title_cont": " "
        },
        "kind": this.props.contactType
      }
    })
    .then(response => {
      this.setState(prevState => ({
        ...prevState,
        contacts: humps.camelizeKeys(response.data).filter((contact, indx) => indx < 5),
        isLoading: false,
      }))
    })
  }

  handleInput(value) {
    this.setState(prevState => ({
      currentValue: value,
      isLoading: true,
    }), () => this.fetchData(value))
  }

  handleOptionClick(contact, kind) {
    const {
      contactPerson, contactPhone,
      address, organizationTitle,
      cityId, cityTitle
    } = contact

    // Inputs insert block
    document.getElementById(`waybill_${kind}_contact_person`).value = contactPerson;
    document.getElementById(`waybill_${kind}_contact_phone`).value = contactPhone;
    document.getElementById(`waybill_${kind}_address`).value =  address;
    document.getElementById(`waybill_${kind}_name`).value = organizationTitle ? organizationTitle : "";
    document.getElementById(`waybill_${kind}_city_title`).value = cityTitle;
    document.getElementById(`waybill_${kind}_city_id`).value = cityId;
    document.getElementById(`city_selector_${kind}_city_title_input`).setAttribute("value", cityTitle);
    document.getElementById(`${kind}Modal`).classList.toggle("is-active");
    document.getElementById(`${kind}_city_id`).classList.toggle("is-hidden")
  }

  fetchData = (value) => {
    const { contactType } = this.state

    this.setState(prevState => ({ ...prevState, isLoading: true }));

    axios.get("/cabinet/addresses/search.json", {
      params: {
        "q": {
          "contact_person_or_organization_title_cont": value
        },
        "kind": contactType
      }
    })
    .then(response => {
      this.setState(prevState => ({
        ...prevState,
        contacts: humps.camelizeKeys(response.data),
        isLoading: false,
      }))
    })
  }

  render() {
    const { currentValue, isLoading, contacts, contactType} = this.state
    return (
      <div  className="address-search-form">
        <Form
          currentValue={ currentValue }
          handleInput={ this.handleInput }
          options={ { loading: isLoading } }
        />
        <Result
          contacts={ contacts }
          contactType={ contactType }
          handleOptionClick={this.handleOptionClick}
        />
      </div>
    );
  }
}

export default AddressSearch;
