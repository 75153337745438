import React from "react";

const Tariff = (props) => (
  <div className="tariff">
    <p>
      <strong>
        {props.translations.fullprice}
      </strong>
    </p>
    <h1>{props.tariff.price + props.tariff.declared_value_fee + props.tariff.fuel_surplus}</h1>
    <p>
      {props.translations.deliverysum}:
      <b> {props.tariff.price}</b>
      {props.translations.tengefull}
    </p>
    <p>
      {props.translations.fuelsurcharge}:
      <b> {props.tariff.fuel_surplus}</b>
      {props.translations.tengefull}
    </p>
    <p>
      {props.translations.valuefee} {props.declared_value} {props.translations.tengefull}:
      <b> {props.tariff.declared_value_fee}</b>
      {props.translations.tengefull}
    </p>
    <p>
      ({props.translations.tariffnotification})
    </p>
    <div className="term">
      <p>
        <strong>
          {props.translations.daysterm}
        </strong>
      </p>
      <h1>
        {props.tariff.min} - {props.tariff.max}
      </h1>
      <p>
        <strong>
          {props.translations.factterm}
        </strong>
      </p>
      <h1>
        {props.tariff.human_range}
      </h1>
    </div>
    <p></p>
  </div>
)

export default Tariff;
