import React from "react";
import Track from "./Track.js"

const Waybill = props => (
  <div>
    { props.sender_title &&
      <div className="waybill">
        <a className="text-right" href="#">
          <h5>{props.translations.close}</h5>
        </a>
        <div className="columns">
          <div className="waybill-details column is-3 has-text-left">
            <h4>{props.translations.waybillDetails}</h4>
            <p className="tracking-type">{props.translations.senderName}</p>
            <p>{props.sender_title}</p>
            <p className="tracking-type">{props.translations.senderAddress}</p>
            <p>{props.orig_region}, {props.orig_address}</p>
            <p className="tracking-type">{props.translations.receiverName}</p>
            <p>{props.receiver_title}</p>
            <p className="tracking-type">{props.translations.receiverAddress}</p>
            <p>{props.dest_region}, {props.dest_address}</p>
            <p className="tracking-type">{props.translations.quantityOfShipments}</p>
            <p>{props.quantity}</p>
            <p className="tracking-type">{props.translations.weigth}</p>
            <p>{props.weigth}</p>
          </div>
          <div className="tracking column is-6 has-text-centered">
            <h4>{props.translations.tracking}</h4>
            {props.parcels.tracks.map((track) =>
              <Track track={track}
              />
            )}
          </div>
          <div className="delivery-details column is-3 has-text-left">
            <h4>{props.translations.deliveryDetails}</h4>
            <p className="tracking-type">Статус</p>
            <span className="tracking-desc">
              <span className={props.delivery_status_css_class}>{props.delivery_status}</span>
            </span>
            <div className="tracking-delivery-details">
              <br></br>
              <a className="btn btn-sm btn-primary" href="#">{props.translations.print}</a>
              <br></br>
              <div className="tracking-success-delivery">
                <p className="tracking-type">{props.translations.deliveryTime}</p>
                <p className="tracking-desc">{props.success_delivery ? props.success_delivery.stamp : ""}</p>
                <p className="tracking-type">{props.translations.received}</p>
                <p className="tracking-desc">{props.success_delivery ? props.success_delivery.receiver_name : ""}</p>
                <p className="tracking-type">{props.translations.position}</p>
                <p className="tracking-desc">{props.success_delivery ? props.success_delivery.receiver_position : ""}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    }
    <p>{props.error}</p>
  </div>
)

export default Waybill;
