import React from "react";
import Tariff from "./Tariff"
import Notification from "./Notification"

const View = (props) => (
  <div className="waybill-calculator-form">
    {props.tariff && <Tariff tariff={props.tariff} declared_value={props.declared_value} translations={props.translations}/>}
    {props.tariff === undefined && <Notification translations={props.translations}/>}
    <div className="tariff-calc-description">
      <small>
        {props.translations.description}
      </small>
    </div>
    <div className="tariff-calc-attention">
      <small>
        {props.translations.attention}
      </small>
    </div>
  </div>
)

export default View;
