import "core-js/stable";
import "regenerator-runtime/runtime";

import Rails from "rails-ujs";
import Turbolinks from "turbolinks";
import React from "react";
import ReactDOM from "react-dom";
import humps from "humps";
import apps from "src/cabinet/js/apps";
import "src/site/scss/main.scss";
import "src/site/js/navbar.js";
import "src/site/js/images.js";
import "src/site/js/rocket.js";
import "src/site/js/phone_mask.js";

Rails.start();
Turbolinks.start();

document.addEventListener("turbolinks:load", () => {
  // Render components
  const tags = document.querySelectorAll("[data-handler=\"react\"]");
  [].forEach.call(tags, (tag) => {
    const data = humps.camelizeKeys(JSON.parse(tag.getAttribute("data-props")));
    console.log(data)
    const componentName = tag.getAttribute("id");
    const Component = apps[componentName];
    // eslint-disable-next-line react/jsx-filename-extension
    ReactDOM.render(<Component {...data} />, tag);
  });
});
