import React from "react";

const Notification = (props) => (
  <div className="tariff-calc-notification">
    <p>
      <strong>{props.translations.notificationtitle}</strong>
    </p>
    <p>
      {props.translations.notification}
    </p>
  </div>
)

export default Notification;
