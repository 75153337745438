import React from "react";
import ResultElem from "./ResultElem";

const Result = (props) => (
  <div id="city-selector-result" className="ajax-input__result">
    <ul>
      {
        props.data.map((elem, idx) => (
          <ResultElem
            id={elem.id}
            idx={idx}
            selected_option={props.selected_option}
            handle_option_click={props.handle_option_click}
            cached_path={elem.cached_path}
            title={elem.title}
          />)
        )
      }
    </ul>
  </div>
)

export default Result;
