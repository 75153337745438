import IMask from "imask";

document.addEventListener("turbolinks:load", () => {
  const inputs = document.querySelectorAll("input[data-mask=phone-mask]");
  if (inputs) {
    [].forEach.call(inputs, (input) => {
      const mask = IMask(input, {
        mask: "+{7}(000)000-00-00",
        lazy: false,
        placeholderChar: "_"
      });
    });
  };
});
