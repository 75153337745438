import React from "react";

const Track = props => (
  <div className="track">
    <span className="label label-info tracking-desc">{props.track.created_at}</span>
    <span className="label label-success tracking-desc">{props.track.location}</span>
    <p className="tracking-desc">{props.track.description}</p>
  </div>
)

export default Track;