import CitySelector from "./CitySelector";
import WaybillTracking from "./WaybillTracking";
import TariffCalculator from "./TariffCalculator";
import AddressSearch from "./AddressSearch";

const apps = {
  CitySelector,
  WaybillTracking,
  TariffCalculator,
  AddressSearch,
};

export default apps;
