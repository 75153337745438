import React from "react";
import CitySelector from "./CitySelector.js"

const Form = (props) => (
  <div>
    <form className="waybill-form" onSubmit={props.getting_price}>
      <div className="field">
        <label>
          {props.translations.wherefrom}:
        </label>
        <div className="control">
          <CitySelector
            name="orig_city" 
            placeholder="Алматы"
            translations={props.translations}
          />
        </div>
      </div>
      <div className="field">
        <label>
          {props.translations.where}:
        </label>
        <div className="control">
          <CitySelector
            name="dest_city" 
            placeholder="Нур-Султан"
            translations={props.translations}
          />
        </div>
      </div>
      <div className="columns metrics">
        <div className="field cloumn is-6">
          <label>
            {props.translations.service}:
          </label>
          <div className="control">
            <div className="select">
              <select name="service">
                <option value="standard">{props.translations.standard}</option>
                <option value="express">{props.translations.express}</option>
              </select>
            </div>
          </div>
        </div>
        <div className="field column is-6 weight">
          <label>{props.translations.weight}({props.translations.kilogram}):</label>
          <div className="control">
            <input type="number" className="input" name="weight" defaultValue="0.3" step="0.1"/>
          </div>
        </div>
      </div>
      <div className="columns metrics">
        <div className="field column is-4 metric">
          <label>{props.translations.length}({props.translations.centimeter}):</label>
          <div className="control">
            <input type="number" className="input" name="length" defaultValue="0" step="0.1"/>
          </div>
        </div>
        <div className="field column is-4 metric">
          <label>{props.translations.width}({props.translations.centimeter}):</label>
          <div className="control">
            <input type="number" className="input" name="width" defaultValue="0" step="0.1"/>
          </div>
        </div>
        <div className="field column is-4 metric">
          <label>{props.translations.height}({props.translations.centimeter}):</label>
          <div className="control">
            <input type="number" className="input" name="height" defaultValue="0" step="0.1"/>
          </div>
        </div>
      </div>
      <div className="field declared_value">
        <label>{props.translations.declaredvalue}({props.translations.tenge}):</label>
        <div className="control">
          <input type="number" className="input" name="declared_value" defaultValue="15000"/>
        </div>
      </div>
      <button>{props.translations.calculate}</button>
    </form>
  </div>
)

export default Form;
