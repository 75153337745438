import React from "react";

const Result = ({contacts, contactType, handleOptionClick}) => (
  <ul className="address-list">
    {
      contacts.map((contact, idx) => (
        <li
          key={contact.id} idx={idx}
          onClick={(e) => { e.preventDefault(); handleOptionClick(contact, contactType)}}>
          <span>
            Имя: {contact.contactPerson}
          </span>
          {
            contact.organizationTitle ?
            (
              <span className="has-text-grey-light">
                Организация: {contact.organizationTitle}
              </span>
            ) :
            (
              <span className="has-text-grey-light">
                Телефон: {contact.contactPhone}
              </span>
            )
          }
        </li>
      ))
    }
  </ul>
)

export default Result;
