import React from "react";
import axios from "axios";
import { debounce } from "lodash";

import CharsCounter from "./CitySelector/CharsCounter";
import Result from "./CitySelector/Result";


class CitySelector extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      minChars: 2,
      currentValue: props.currentValue || "",
      currentId: props.currentId || "",
      data: [],
      isLoading: false,
      selectedOption: 0
    };
    this.handleInput = this.handleInput.bind(this);
    this.handleOptionClick = this.handleOptionClick.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.fetchData = debounce(this.fetchData, 350);
  }

  handleInput(value) {
    this.setState(prevState => ({
      currentValue: value,
      selectedOption: 0,
      data: []
    }), () => this.fetchData(value))
  }

  handleOptionClick(id, value) {
    this.setState(prevState => ({
      ...prevState,
      data: [],
      currentValue: value,
      currentId: id,
    }))
  }

  handleKeyPress(e) {
    const { selectedOption } = this.state;
    const { data } = this.state;
    const nextIndex = data.length - 1 <= selectedOption ? 0 : selectedOption + 1;
    const prevIndex = selectedOption > 0 ? selectedOption - 1 : data.length - 1;


    if (e.key === "ArrowDown") {
      e.preventDefault();
      this.setState(prevState => ({
        ...prevState,
        selectedOption: nextIndex
      }))
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      this.setState(prevState => ({
        ...prevState,
        selectedOption: prevIndex
      }))
    } else if (e.key === "Enter") {
      e.preventDefault();
      this.setState(prevState => ({
        ...prevState,
        data: [],
        currentValue: prevState.data[selectedOption] ? prevState.data[selectedOption].title : "",
        currentId: prevState.data[selectedOption] ? prevState.data[selectedOption].id : "",
      }))
    }
  }

  fetchData = (value) => {
    const { fetchFrom } = this.props;
    const { minChars } = this.state;

    if (value.length >= minChars) {
      this.setState(prevState => ({ ...prevState, isLoading: true }));
      axios.get(`http://api.gpserv.work/public/v1/regions/${fetchFrom}?title=${value}`)
        .then((response) => {
          this.setState(prevState => ({
            ...prevState,
            data: response.data.regions,
            isLoading: false,
            selectedOption: 0
          }))
        })
    } else {
      this.setState(prevState => ({
        ...prevState,
        data: [],
        selectedOption: 0
      }))
    }
  }

  render() {
    const { className, resourceName, idField, valueField, translation } = this.props;
    const { minChars, currentId, currentValue, data, isLoading, selectedOption } = this.state;

    return (
      <div className={`control ajax-input ${isLoading && "is-loading"}`}>

        <input
          type="hidden"
          value={currentValue}
          id={`${resourceName}_${valueField}`}
          name={`${resourceName}[${valueField}]`}
        />
        <input
          type="hidden"
          value={currentId}
          id={`${resourceName}_${idField}`}
          name={`${resourceName}[${idField}]`}
        />
        <input
          value={currentValue}
          type="text"
          id={`city_selector_${valueField}_input`}
          className={className}
          onChange={(e) => this.handleInput(e.target.value)}
          onKeyDown={(e) => this.handleKeyPress(e)}
        />
        { currentValue.length >= minChars && <Result handleOptionClick={this.handleOptionClick} data={data} selectedOption={selectedOption} />}
        <CharsCounter
          minChars={minChars}
          currentCharsLength={currentValue.length}
          translation={translation}
          idField={idField}
        />
      </div>
    );
  }
}

export default CitySelector;
