import React from "react";
import Form from "./TariffCalculator/Form";
import View from "./TariffCalculator/View";

class TariffCalculator extends React.Component {

  state = {
    data: undefined,
    declared_value: undefined
  }

  gettingPrice = async (event) => {
    event.preventDefault();
    const orig_city_id = event.target.elements.orig_city_id.value
    const dest_city_id = event.target.elements.dest_city_id.value
    const service = event.target.elements.service.value
    const weight = event.target.elements.weight.value
    const length = event.target.elements.length.value
    const width = event.target.elements.width.value
    const height = event.target.elements.height.value
    const declared_value = event.target.elements.declared_value.value
    const api_path = `http://api.gpserv.work/public/v1/calculate?`
    const api_url = await fetch(`${api_path}origin_id=${orig_city_id}&destination_id=${dest_city_id}&weight=${weight}&service=${service}&declared_value=${declared_value}&w=${width}&l=${length}&h=${height}`);
    const data = await api_url.json();
    this.setState ({
      data: data.calculation,
      declared_value: declared_value
    })
  }

  render () {
    return(
      <div className="tarrif-calculator" >
        <h1>{this.props.translations.title}</h1>
        <div className="columns">
          <div className="column is-6">
            <Form
              getting_price={this.gettingPrice}
              translations={this.props.translations}
            />
          </div>
          <div className="column is-6 waybill-calculator-view">
            <View
              tariff={this.state.data}
              declared_value={this.state.declared_value}
              translations={this.props.translations}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default TariffCalculator;
